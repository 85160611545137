import React, { useState, useEffect } from 'react';
import './Subcategory.css';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';

const SUBCATEGORY_TITLE_MAP = {
    'nba': 'NBA',
    'wnba': 'WNBA',
    'ncaab': 'NCAAB',
    'mls': 'MLS',
    'epl': 'EPL',
    'mlb': 'MLB',
    'nfl': 'NFL',
    'ufc': 'UFC',
};

const Subcategory = () => {
    const { id, subcategoryid } = useParams();
    const [posts, setPosts] = useState([]);
    const [currentsubcategory, setsubcurrentcategory] = useState('');

    useEffect(() => {
        const subcategory = subcategoryid;

        setsubcurrentcategory(capitalizeFirstLetter(subcategory));

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/blogs/category/${id}/${subcategoryid}/`, config);
                setPosts(res.data);
            } catch (err) {
                console.error("Error fetching posts for subcategory:", err);
            }
        };

        fetchData();
    }, [id, subcategoryid]);

    const capitalizeFirstLetter = (word) => {
        if (!word) return '';

        if (SUBCATEGORY_TITLE_MAP[word]) {
            return SUBCATEGORY_TITLE_MAP[word];
        }

        return word
            .split('-')
            .map(subWord => subWord.charAt(0).toUpperCase() + subWord.slice(1))
            .join(' ');
    };


    const getsubcategoryposts = () => {
        const list = posts.map(blogPost => (
            <div key={blogPost.id} className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div className="col p-4 d-flex flex-column position-static">
                    <strong className="d-inline-block mb-2 text-primary">{capitalizeFirstLetter(blogPost.category)}</strong>
                    <h3 className="mb-0">{blogPost.title}</h3>
                    <div className="mb-1 text-muted">{blogPost.month} {blogPost.day}</div>
                    <p className="card-text mb-auto">{blogPost.excerpt}</p>
                    <Link to={`/blogs/${blogPost.slug}`} className="stretched-link">Continue reading</Link>
                </div>
                <div className="col-auto d-none d-lg-block">
                    <img width='200' height='250' src={blogPost.thumbnail} alt='thumbnail' />
                </div>
            </div>
        ));

        return list;
    };



    return (
        <div className='container mt-3'>
            <h3 className='display-4'>{currentsubcategory}</h3>
            
            <div className='row'> 
                <div className='col-md-9'>
                    {getsubcategoryposts()}
                </div>                
                <Sidebar />         
            </div>
        </div>
    );
};

export default Subcategory;
