import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Login.css';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/accounts/login/`, formData);
            console.log("Login successful:", response.data);
            
            localStorage.setItem("authToken", response.data.token);

            setSuccessMessage("Login successful! Redirecting...");

            setTimeout(() => {
                navigate('/create');
            }, 1500);

        } catch (error) {
            if (error.response) {
                console.error("Login failed:", error.response.data.detail || "Unknown error.");
                setErrorMessage(error.response.data.detail || "Unknown error.");
            } else if (error.request) {
                console.error("No response received:", error.request);
                setErrorMessage("No response received from the server.");
            } else {
                console.error("Error setting up the request:", error.message);
                setErrorMessage("An error occurred. Please try again.");
            }
        }

        // Reset password field for security
        setFormData({
            ...formData,
            password: ''
        });
        navigate.apply('/create')
    };

    return (
        <div className="login-form">
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                {successMessage && <p className="success-message">{successMessage}</p>} {/* Display success message */}
                <div className="form-group">
                    <label>Username:</label>
                    <input type="text" name="username" value={formData.username} onChange={handleChange} required />
                </div>

                <div className="form-group">
                    <label>Password:</label>
                    <input type="password" name="password" value={formData.password} onChange={handleChange} required />
                </div>

                <button type="submit">Login</button>
                <p>Don't have an account? <Link className="link" to="/signup">Sign up</Link></p>
            </form>
        </div>
    );
};

export default Login;
