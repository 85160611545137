import React, { useState, useEffect } from 'react';
import './BlogDetail.css';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../Sidebar/Sidebar';

const BlogDetail = (props) => {
    const { slug } = useParams();
    const [post, setPost] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/blogs/${slug}`);
                setPost(res.data);
            } catch (err) {
                console.error("Error fetching post data:", err);
            }
        };

        fetchData();
    }, [slug]);

    const createPost = () => {
        return {__html: post.content}
    };

    const capitalizeAll = (word) => word ? word.toUpperCase() : '';

    return (
        <div className='container mt-3'>
            <div className="row">
                <div className="col-md-9">  {/* Main content column */}
                    {/* Display Category and Subcategory */}
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: 'small', color: 'blue' }}>
                        <span>{capitalizeAll(post?.subcategory?.category?.name)}</span>
                        <span style={{ margin: '0 8px' }}>|</span>
                        <span>{capitalizeFirstLetter(post?.subcategory?.name)}</span>
                    </div>
    
                    <h1 className='display-4 mt-2'>{post.title}</h1>
                    
                    <p style={{ color: 'grey', fontSize: 'medium' }}>{post.excerpt}</p>
    
                    <h6>By: {post?.user?.first_name} {post?.user?.last_name}</h6>
    
                    <p>Last Updated: {new Date(post.updated).toLocaleString()}</p>
    
                    {post.thumbnail && <img src={post.thumbnail} alt="Post Thumbnail" style={{ width: '100%', height: 'auto', marginTop: '20px', marginBottom: '20px' }} />}
                    
                    <div className='mt-5 mb-5' dangerouslySetInnerHTML={createPost()} />
                    <hr />
                </div>
                <Sidebar />
            </div>
        </div>
    );
};

const capitalizeFirstLetter = (word) => {
    if (word)
        return word.charAt(0).toUpperCase() + word.slice(1);
    return '';
};

export default BlogDetail;
