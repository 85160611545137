import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <div className="col-md-3">
        <div className="position-sticky" style={{ top: '2rem' }}>
            <div className="p-4 mb-3 bg-light rounded">
                <h4 className="fst-italic">Our Mission at Athlete Digest</h4>
                <p className="mb-0">
                    In a world where the lines blur between information and distraction, Athlete Digest seeks clarity. We champion authentic athletic stories, news, and insights while nurturing a community that values genuine connections. As we embark on a journey to bridge journalism with the essence of social interaction, join us. Be part of the revolution.
                </p>
            </div>

            <div className="p-4 bg-light rounded">
                <h4 className="fst-italic">Join the Conversation</h4>
                <p className="mb-0">
                    Curate your athletic journey. Connect with like-minded individuals, share your stories, and discover the unexplored terrains of sports. <Link className="link" to="/signup">Sign up</Link> now and be part of our growing community.
                </p>
            </div>
        </div>
    </div>
  );
}

export default Sidebar;
