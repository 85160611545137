import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useSearchParams } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar'; 

const SearchResults = () => {
  const [results, setResults] = useState([]);
  const [searchParams] = useSearchParams();
  const query = searchParams.get("query");

  useEffect(() => {
    async function fetchResults() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/blogs/`, { params: { q: query } });
        if (response.data && response.data.object_list) {
          setResults(response.data.object_list);
        }
      } catch (error) {
        console.error("Failed to fetch search results", error);
      }
    }
    fetchResults();
  }, [query]);

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-9">
          <div className="search-results-container">
            <h2>Search Results</h2>
            {results.length === 0 ? (
              <p>No Results Found for "{query}"</p>
            ) : (
              results.map(post => (
                <div key={post.id} style={{ borderBottom: '1px solid lightgray', paddingBottom: '1rem', marginBottom: '1rem' }}>
                  <small className="text-muted">{post.subcategory.name}</small>
                  <h4><Link to={`/blogs/${post.slug}`}>{post.title}</Link></h4>
                  <p>{post.excerpt}</p>
                  <p>{new Date(post.publish_date).toLocaleString()}</p>
                  <div className="float-right">
                    <img src={post.thumbnail} alt={post.title} style={{ maxWidth: '100px' }} />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <Sidebar />
      </div>
    </div>
  );
};

export default SearchResults;
