import React from 'react';
import './About.css';

const About = () => {
    return (
        <div className="about-container">
            <h1>About Athlete Digest</h1>
            <p>
                At Athlete Digest we aim to be at the cornerstone of all reliable information in the realm of all athletics. From the latest sports updates to in-depth reviews of the newest athletic equipment, our platform offers comprehensive coverage tailored for athletes from all backgrounds and disciplines.
            </p>
            <p>
                Our mission is simple: Provide trustworthy and up-to-date content for every sports enthusiast, whether you're a casual jogger or a professional athlete. Athlete Digest is more than just a news site—it's a community dedicated to celebrating the spirit of sportsmanship and excellence.
            </p>
            <p>
                Thank you for trusting Athlete Digest as your go-to source for all things athletic.
            </p>
        </div>
    );
};

export default About;
