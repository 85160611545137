import React, { useState, useEffect } from 'react';
import './Home.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';

const Home = () => {
    const [HighlightedPost, setHightlightedPost] = useState({});
    const [FeaturedPosts, setFeaturedPosts] = useState([]);

    useEffect(() => {
        const fetchHighlightedPosts = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/blogs/highlighted/`);
                if (res.data && res.data[0]) {
                    setHightlightedPost(res.data[0]);
                } else {
                    console.error("API didn't return expected highlighted post data.");
                }
            }
            catch (err) {
                console.error("Error fetching featured posts: ", err);
            }
        }

        fetchHighlightedPosts();
    }, []);

    useEffect(() => {
        const fetchFeaturedPosts = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/blogs/featured/`);
                if (res.data.length > 0) {
                    setFeaturedPosts(res.data);
                } else {
                    console.error("API didn't return expected featured post data.");
                }
            }
            catch (err) {
                console.error("Error fetching featured posts: ", err);
            }
        }
    
        fetchFeaturedPosts();
    }, []);

    return (
        <div>
            <main className="container">
                <Link to={`/blogs/${HighlightedPost.slug}`} className="highlighted-post-link">
                    <div className='row'>
                        {/* Highlighted Posts */}
                        <div className='col-md-12'>
                            <div className="p-4 p-md-5 mb-4 text-white rounded bg-dark">
                                <div className="col-md-6 px-0">
                                    <h1 className="display-4 fst-italic">{HighlightedPost.title}</h1>
                                    <p className="lead my-3">{HighlightedPost.excerpt}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
                <div className='row'> 
                    {/* Featured Posts */}
                    <div className='col-md-9'>
                        <div className='row'>
                            {FeaturedPosts.map((post) => (
                                <div key={post.id} className="col-md-6">
                                    <Link to={`/blogs/${post.slug}`} className="featured-post-link">
                                        <div className="card mb-3" style={{ maxWidth: '600px' }}>
                                            <div className="row g-0">
                                                <div className="col-md-4">
                                                    <img src={post.thumbnail} className="img-fluid rounded-start" alt="Post thumbnail" />
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="card-body">
                                                        <h5 className="card-title">{post.title}</h5>
                                                        <p className="card-text">{post.excerpt}</p>
                                                        <p className="card-text">
                                                            <small className="text-body-secondary">
                                                                {post.publish_date ? new Date(post.publish_date).toLocaleDateString("en-US", { month: 'short', day: 'numeric' }) : ""}
                                                            </small>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                    <Sidebar />
                </div>
            </main>
        </div>
    );
}

export default Home;
