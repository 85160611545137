import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './hocs/Layout';
import Home from "./components/Home/Home";
import SearchResults from "./components/Search/SearchResults";
import Category from './components/Category/Category';
import Subcategory from './components/Subcategory/Subcategory';
import BlogDetail from './components/Blog/BlogDetail';
import Careers from "./components/Careers/Careers";
import ContactUs from "./components/Contact/Contact";
import Login from "./components/Login/Login";
import Create from "./components/Create/Create";
import Signup from "./components/Signup/Signup";
import About from "./components/About/About";
import FAQ from "./components/FAQ/FAQ";



function App() {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/search" element={<SearchResults />} />
                    <Route path="/:id" element={<Category />} />
                    <Route path="/:id/:subcategoryid" element={<Subcategory />} />
                    <Route path="/blogs/:slug" element={<BlogDetail />} />
                    <Route path="/signup" element={<Signup /> }/>
                    <Route path="/login" element={<Login />}/>
                    <Route path="/join-us" element={<Careers />}/>
                    <Route path="/contact" element={<ContactUs />}/>
                    <Route path="/create" element={<Create />}/>
                    <Route path="/about" element={<About />}/>
                    <Route path="/FAQ" element={<FAQ />}/>
                </Routes>
            </Layout>
        </Router>
    );
}

export default App;