import React, { useState } from 'react';
import './Contact.css'

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/engagements/contact/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
    
            if (response.ok) {
                setSuccessMessage('Thank you for reaching out! We will get back to you shortly.');
            } else {
                const data = await response.json();
                setErrorMessage(data.error || 'Something went wrong!');
            }
    
            // Reset the form after submission
            setFormData({
                name: '',
                email: '',
                subject: '',
                message: ''
            });

        } catch (error) {
            setErrorMessage("There was an issue connecting to the server.");
            console.error("Error during form submission:", error);
        }
    };

    return (
        <div className="contact-form">
            <h2>Contact Us</h2>
            {successMessage && <p className="success-message">{successMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <input 
                        type="text" 
                        name="name" 
                        value={formData.name} 
                        onChange={handleChange} 
                        placeholder="Name" 
                        required 
                    />
                </div>

                <div className="form-group">
                    <input 
                        type="email" 
                        name="email" 
                        value={formData.email} 
                        onChange={handleChange} 
                        placeholder="Email Address" 
                        required 
                    />
                </div>

                <div className="form-group">
                <label>Subject:</label>
                    <input 
                        type="text" 
                        name="subject" 
                        value={formData.subject} 
                        onChange={handleChange}
                        required 
                    />
                </div>

                <div className="form-group">
                <label>Message:</label>
                    <textarea 
                        name="message" 
                        value={formData.message} 
                        onChange={handleChange}
                        required 
                    />
                </div>

                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default ContactUs;
