import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Create.css';

const Create = () => {
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [formData, setFormData] = useState({
        title: '',
        content: '',
        excerpt: '',
        category: '',
        subcategory: ''
    });

    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/blogs/category/`);
                setCategories(response.data.categories);
            } catch (error) {
                console.error("Failed to fetch categories:", error);
            }
        };

        fetchCategories();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'category') {
            fetchSubcategories(value);
        }
    };

    const fetchSubcategories = async (categorySlug) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/blogs/category/${categorySlug}/`);
            const subCategoriesData = response.data.current_category_subcategories.map(subCatData => {
                return { name: subCatData.subcategory, slug: subCatData.slug };
            });
            setSubcategories(subCategoriesData);
        } catch (error) {
            console.error("Error fetching subcategories:", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem("authToken");

        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                },
            };

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/blogs/create/`, formData, config);
            
            setSuccessMessage("Post created successfully!");

            setTimeout(() => {
                window.location.href = `/blogs/${response.data.slug}/`; 
             }, 2000);

            setFormData({
                title: '',
                content: '',
                excerpt: '',
                category: '',
                subcategory: ''
            });
        } catch (error) {
            console.error("Error creating this post:", error);
            setErrorMessage("Error creating this post.");
        }
    };

    return (
        <div className="create-blog-form">
            <h2>Create Post</h2>

            {successMessage && <p className="success-message">{successMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}

            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Title:</label>
                    <input type="text" name="title" value={formData.title} onChange={handleChange} required />
                </div>

                <div className="form-group">
                    <label>Category:</label>
                    <select name="category" value={formData.category} onChange={handleChange}>
                        <option value="">Select a category</option>
                        {categories.map(cat => (
                            <option key={cat.slug} value={cat.slug}>{cat.name}</option>
                        ))}
                    </select>
                </div>

                {formData.category && (
                    <div className="form-group">
                        <label>Subcategory:</label>
                        <select name="subcategory" value={formData.subcategory} onChange={handleChange}>
                            <option value="">Select a subcategory</option>
                            {subcategories.map(sub => (
                                <option key={sub.name} value={sub.name}>{sub.name}</option>
                            ))}
                        </select>
                    </div>
                )}

                <div className="form-group">
                    <label>Content:</label>
                    <textarea name="content" rows="10" value={formData.content} onChange={handleChange} required></textarea>
                </div>

                <div className="form-group">
                    <label>Excerpt:</label>
                    <textarea name="excerpt" rows="5" value={formData.excerpt} onChange={handleChange} required></textarea>
                </div>
                
                <button type="submit">Publish</button>
            </form>
        </div>
    );
};

export default Create;
