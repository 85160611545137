import React, { useState, useEffect } from 'react';
import './Category.css';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';

const CATEGORY_TITLE_MAP = {
    'mma': 'Mixed Martial Arts'
};

const Category = () => {
    const { id: categorySlug } = useParams();
    const [data, setData] = useState({ categories: [], current_category_subcategories: [] });
    const [currentCategory, setCurrentCategory] = useState('');

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/blogs/category/${categorySlug}/`, config);
                setData(res.data);
                setCurrentCategory(capitalizeFirstLetter(categorySlug));
            } catch (err) {
                console.error("Error fetching data:", err);
            }
        };

        fetchData();
    }, [categorySlug]);

    const capitalizeFirstLetter = (word) => {
        if (!word) return '';

        if (CATEGORY_TITLE_MAP[word]) {
            return CATEGORY_TITLE_MAP[word];
        }

        return word
            .split('-')
            .map(subWord => subWord.charAt(0).toUpperCase() + subWord.slice(1))
            .join(' ');
    };

    const getCategoryBlogs = (subcategoryData) => {
        const list = subcategoryData.posts.slice(0, 5).map(blogPost => (
            <Link to={`/blogs/${blogPost.slug}`} key={blogPost.id} className="card-link">
                <div key={blogPost.id} className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div className="col p-4 d-flex flex-column position-static">
                        <strong className="d-inline-block mb-2 text-primary">{capitalizeFirstLetter(blogPost.category)}</strong>
                        <h3 className="mb-0">{blogPost.title}</h3>
                        <p>By {blogPost.user.first_name} {blogPost.user.last_name} on {new Date(blogPost.publish_date).toLocaleDateString()}</p>
                    </div>
                    <div className="col-auto d-none d-lg-block">
                        <img width='200' height='250' src={blogPost.thumbnail} alt='thumbnail' />
                    </div>
                </div>
            </Link>
        ));

        const subcategoryslug = subcategoryData && subcategoryData.posts && subcategoryData.posts[0] && subcategoryData.posts[0].subcategory && subcategoryData.posts[0].subcategory.slug;

        return (
            <div key={subcategoryData.subcategory} style={{ marginBottom: '2rem', borderBottom: '1px solid black', paddingBottom: '1rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h4>{capitalizeFirstLetter(subcategoryData.subcategory)}</h4>
                    <Link to={`/${categorySlug}/${subcategoryslug}`}>View All</Link>
                </div>
                {list}
            </div>
        );
    };
    

    return (
        <div className='container mt-3'>
            <h3 className='display-4'>{currentCategory}</h3>
            <hr style={{borderTop: '1px solid black'}} />
            
            <div className='row'>
                <div className='col-md-9'>
                    {data.current_category_subcategories.map(getCategoryBlogs)}
                </div>
                <Sidebar />
            </div>
        </div>
    );
};

export default Category;

