import React from 'react';
import './FAQ.css';

const FAQ = () => {
    const faqs = [
        {
            question: "How do I subscribe to Athlete Digest newsletters?",
            answer: "You can subscribe to our newsletters from our homepage. Just enter your email in the subscription box and stay updated with all the latest news!"
        },
        {
            question: "Can I contribute an article to Athlete Digest?",
            answer: "Yes, we're always looking for enthusiastic writers! Visit our 'Join-Us' section for more details."
        },
    ];

    return (
        <div className="faq-container">
            <h1>Frequently Asked Questions</h1>
            {faqs.map((faq, index) => (
                <div key={index} className="faq-item">
                    <h2>{faq.question}</h2>
                    <p>{faq.answer}</p>
                </div>
            ))}
        </div>
    );
};

export default FAQ;
