import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => (
    <footer className="footer">
        <div className="footer-content">
            <div className="footer-logo">
                Athlete Digest
            </div>
            <div className="footer-links">
                <Link to="/contact">Contact</Link>
                <Link to="/about">About</Link>
                <Link to="/faq">FAQ</Link>
                <Link to="/join-us">Join Us</Link>
            </div>
        </div>
        <div className="footer-copyright">
            © {new Date().getFullYear()} Athlete Digest. All Rights Reserved.
        </div>
    </footer>
);

export default Footer;
