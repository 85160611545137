import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const Search = () => {
  const [query, setQuery] = useState('');
  const [showInput, setShowInput] = useState(false);
  const searchRef = useRef(null);
  const navigate = useNavigate();

  const handleInputChange = e => {
    setQuery(e.target.value);
  };

  const triggerSearch = async () => {
    if (query) {
      navigate(`/search?query=${query}`);
      setQuery('');
    }
  };

  const handleSearch = (e) => {
    if (e.key === 'Enter' && query) {
      triggerSearch();
    }
  };

  const handleClickOutside = e => {
    if (searchRef.current && !searchRef.current.contains(e.target)) {
      setShowInput(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`search-container ${showInput ? 'show-input' : ''}`} ref={searchRef}>
      {showInput && (
        <div className="input-group rounded">
          <input
            type="search"
            className="form-control rounded"
            placeholder="Search"
            aria-label="Search"
            aria-describedby="search-addon"
            value={query}
            onChange={handleInputChange}
            onKeyPress={handleSearch}
          />
          <span className="input-group-text border-0" id="search-addon" onClick={triggerSearch}>
            <i className="fas fa-search"></i>
          </span>
        </div>
      )}
      {!showInput && (
        <button className="input-group-text border-0" id="search-addon" onClick={() => setShowInput(!showInput)}>
          <i className="fas fa-search"></i>
        </button>
      )}
    </div>
  );
};

export default Search;
