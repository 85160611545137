import React from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import Search from "../Search/Search";

const Navbar = () => {
    return (
        <div className="container">
            <header className="blog-header py-3">
                <div className="row flex-nowrap justify-content-between align-items-center">
                    <div className="col-4 pt-1">
                        <Link className="link-secondary" to="/#">Subscribe</Link>
                    </div>
                    <div className="col-4 text-center">
                      <Link className="blog-header-logo text-dark custom-logo-size" to="/">Athlete Digest</Link>
                    </div>
                    <div className="col-4 d-flex justify-content-end align-items-center">
                        <Search />
                    </div>
                </div>
            </header>

            <div className="nav-scroller py-1 mb-2">
                <nav className="nav d-flex justify-content-between">
                    <Link className="p-2 link-secondary" to="/soccer">Soccer</Link>
                    <Link className="p-2 link-secondary" to="/basketball">Basketball</Link>
                    <Link className="p-2 link-secondary" to="/baseball">Baseball</Link>
                    <Link className="p-2 link-secondary" to="/football">Football</Link>
                    <Link className="p-2 link-secondary" to="/golf">Golf</Link>
                    <Link className="p-2 link-secondary" to="/mma">MMA</Link>
                    <Link className="p-2 link-secondary" to="/hockey">Hockey</Link>
                    <Link className="p-2 link-secondary" to="/softball">Softball</Link>
                    <Link className="p-2 link-secondary" to="/boxing">Boxing</Link>
                    <Link className="p-2 link-secondary" to="/tennis">Tennis</Link>
                    <Link className="p-2 link-secondary" to="/outdoors">Outdoors</Link>
                    <Link className="p-2 link-secondary" to="/gamblers-gridiron">Gamblers Gridiron</Link>
                </nav>
            </div>
        </div>
    );
};

export default Navbar;

