import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import Footer from "../components/Footer/Footer";

const layout = (props) => (
    <div>
        <Navbar />
        {props.children}
        <Footer />
    </div>
);

export default layout;
