import React from 'react';
import { Link } from 'react-router-dom';
import './Careers.css';

const Careers = () => {
    return (
        <div className="careers-page">
            <h1>Join Our Team at Athlete Digest</h1>
            <p>Are you passionate about sports, writing, and sharing unique stories? Athlete Digest is always on the lookout for talented content creators like you!</p>
            <p>Whether you're a seasoned journalist, a budding writer, or simply someone with a story to tell, we believe in providing a platform for voices that can inspire, educate, and entertain our readers.</p>
            <Link to="/login" className="apply-btn">Apply Now</Link>
            <p>If you have any questions regarding the application process, don't hesitate to <Link to="/contact">contact us</Link>.</p>
        </div>
    );
}

export default Careers;
